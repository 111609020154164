import React, {Component, useEffect, useState} from "react";
import Slider from "react-slick";
import { CustomPrevArrow, CustomNextArrow } from './SliderComponets';
import { db } from './firebaseConfig';
import { collection, doc, getDocs, runTransaction } from 'firebase/firestore'
import { Link } from "react-router-dom";


export default function ProductListPage(){
    const [models, setModels] = useState([])
    const modelsCollectionRef = collection(db, "Models")

    useEffect(() => {
        window._hsq.push(['setPath', `/product-list`])
        if( !models.length ){ getModels() }   
    }, [])

    const getModels = async () => {
        const data = await getDocs(modelsCollectionRef)
        console.log(data.docs)
        setModels(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    return (
        <div className="container">
            {/*<ModelsListMenu />*/}
            <div className="row mt-5">
                <div className="col-12 col-md-6 offset-md-3 mb-4">
                    <h1 className="fw-bold">Models</h1>
                    <p className="text-secondary">Standard models - equipped with all the features for modular living. Spec out your model in the customizer. </p>
                </div>

                {models.map((model) => {
                    return (
                        <div className="col-12 col-md-6 p-md-3 pb-md-0">
                          <Link to={`/product/${model.id}`}>
                            <div className="row position-relative rounded overflow-hidden">
                                <div className="px-0">
                                    <ProductListImageSlider images={model.images}/>
                                </div>
                                
                                <div className="position-absolute w-100" style={{bottom:0,left:0,background: 'linear-gradient(transparent, black)'}}>
                                    <div className="d-flex align-items-end justify-content-between mx-1 text-light p-3">
                                        <div className="text-start me-3">
                                            <h3 className="fw-bold mb-0 text-light">{ model.name }</h3>
                                            <p className="mb-0 text-secondary text-light ">Starting At ${model.pricestring}</p>
                                        </div>
                                        <div className="text-end">
                                            <p className="mb-0 text-secondary text-light fw-bold">{ model.squarefootage } SQ FT</p>
                                            <p className="mb-0 text-secondary text-light">{ model.bedrooms } Bed | {model.bathrooms} Bath</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                    )
                })}

                
            </div>
        </div>
    )
}

class ProductListImageSlider extends Component {

    constructor(props){
        super(props)
        console.log(props) 
    }

    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    centerMode: true
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 5000,
                settings: {
                }
            },

        ]
      };
      return (
        <div className='pb-0'>
          <Slider {...settings} >

            {this.props.images.map((image) => {
                return (
                    <div>
                        <div className='rounded overflow-hidden d-flex justify-content-center position-relative'>
                            <img style={{ 'width': '100%' }} src={image} alt="home"></img>
                        </div>
                    </div>
                )
            })}
            
          </Slider>
        </div>
        
      );
    }
  }

  function sliderSlide(){

  }

  function ModelsListMenu(){
      return (
          <div className="row">
              <div className="col-12 d-flex border-top border-bottom py-3">
                  <div className="me-3">
                      <p className="mb-0">Residential</p>
                  </div>
                  <div className="me-3">
                      <p className="mb-0">Disaster Relief</p>
                  </div>
                  <div className="me-3">
                      <p className="mb-0">1 Bed</p>
                  </div>
                  <div className="me-3">
                      <p className="mb-0">2 Bed</p>
                  </div>
                  <div className="me-3">
                      <p className="mb-0">Custom Build</p>
                  </div>
              </div>
          </div>
      )
  }