import React, {Component, useEffect} from "react";
import Slider from "react-slick";
import Tide from './assets/1.jpg';
import Tide2 from './assets/block-home-2/7.jpg';
import Tide3 from './assets/2.jpg';
import Agora from './assets/block-home-9/Renders_1 - Photo.jpg';
import Agora2 from './assets/block-home-9/Renders_5 - Photo.jpg';
import Agora3 from './assets/block-home-9/Renders_6 - Photo.jpg';
import Twoscompany from './assets/block-home-10/3renders_2 - Foto.jpg';
import Twoscompany2 from './assets/block-home-10/4renders_12 - Foto.jpg';
import Twoscompany3 from './assets/block-home-10/Renders_9 - Foto.jpg';
import Loggia from './assets/block-home-13/5.jpg';
import Loggia2 from './assets/block-home-13/15.jpg';
import Loggia3 from './assets/block-home-13/6.jpg';


export default function AboutPage(){

  useEffect(() => {
    window._hsq.push(['setPath', `/about`])
  })


  return(
    <div>
      <Section1/>
      <Section2/>
      <Section3/>
    </div>
  )
}

function Section1(){
  return(
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-8">
          <div className="text-start px-3 py-5">
            <h1 className="text-secondary">Your Partner in Modular Structure Design & Development</h1>
            <h1>Block Co.</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

class Section2 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      centerMode: false,
      slidesToScroll: 1,
      responsive: [
          {
              breakpoint: 768,
              settings: {
                  slidesToShow: 1,
              }
          }
      ]
    };

    return (
      <div className='py-5 overflow-hidden'>
        <Slider {...settings}>
          <div>
              <div className='rounded m-3 overflow-hidden d-flex justify-content-center'>
                  <img style={{'height': '270px'}} src={Tide} alt="home"></img>
              </div>
          </div>
          <div>
              <div className='rounded m-3 overflow-hidden d-flex justify-content-center'>
                  <img style={{'height': '270px'}} src={Agora3} alt="home"></img>
              </div>
          </div>
          <div>
              <div className='rounded m-3 overflow-hidden d-flex justify-content-center'>
                  <img style={{'height': '270px'}} src={Twoscompany} alt="home"></img>
              </div>
          </div>
          <div>
              <div className='rounded m-3 overflow-hidden d-flex justify-content-center'>
                  <img style={{'height': '270px'}} src={Loggia3} alt="home"></img>
              </div>
          </div>
          <div>
              <div className='rounded m-3 overflow-hidden d-flex justify-content-center'>
                  <img style={{'height': '270px'}} src={Agora2} alt="home"></img>
              </div>
          </div>
          <div>
              <div className='rounded m-3 overflow-hidden d-flex justify-content-center'>
                  <img style={{'height': '270px'}} src={Twoscompany3} alt="home"></img>
              </div>
          </div>
          <div>
              <div className='rounded m-3 overflow-hidden d-flex justify-content-center'>
                  <img style={{'height': '270px'}} src={Tide3} alt="home"></img>
              </div>
          </div>
          <div>
              <div className='rounded m-3 overflow-hidden d-flex justify-content-center'>
                  <img style={{'height': '270px'}} src={Agora} alt="home"></img>
              </div>
          </div>
          <div>
              <div className='rounded m-3 overflow-hidden d-flex justify-content-center'>
                  <img style={{'height': '270px'}} src={Twoscompany2} alt="home"></img>
              </div>
          </div>
          <div>
              <div className='rounded m-3 overflow-hidden d-flex justify-content-center'>
                  <img style={{'height': '270px'}} src={Loggia} alt="home"></img>
              </div>
          </div>
        </Slider>
      </div>
      
    );
  }
}

function Section3(){
  return(

    <div className="container-fluid">
      <div className="row px-3 mb-5">
        <div className="col-12 col-md-8">
          <div className="row">
            <div className="col-12 text-start">
              <p className="text-secondary fw-bold text-uppercase">About Us</p>
            </div>
            <div className="col-12 text-start pt-3">
              <h3>The only way to set the pace is to set the standard.</h3>
            </div>
            <div className="col-12 text-start pt-1">
              <h3>At Block, we believe what we do today, shapes the culture of tomorrow. We are a specialized team of passionate and driven innovators committed to creating work that matters. To learn, unlearn and relearn, is to evolve. So, we're constantly shedding our skin to grow a thicker one—because the only way to stay ahead is to lead. Because at Block, we're not in the business of selling dreams, we make them a reality.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}