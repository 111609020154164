import { render } from "@testing-library/react"
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { PopupButton } from '@typeform/embed-react';
import bannerone from './assets/block-home-9/Renders_1 - Photo.jpg';



export const ContactForm = () => {
    useEffect(() => {
        window._hsq.push(['setPath', `/contact`])
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: '22797701',
                    formId: 'c1a96c7f-8a80-4462-b102-92ac7e90df38',
                    target: '#hubspotForm'
                })
            }
        });
    }, []);



    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-lg-6 overflow-hidden" style={{height: '80vh'}}>
                    <div className="w-100 h-100 d-flex justify-content-center flex-column align-items-center mt-lg-0 mt-5">
                        <h1 className="larger-text fw-bold">Contact</h1>
                        <div id="hubspotForm" style={{maxWidth: '500px', width: '100%'}}></div>
                    </div>            
                </div>
                <div className="col-lg-6 gx-0 h-50 overflow-hidden desktop-only" style={{border: "6px solid white", borderLeft: 'none'}}>
                    <div className="d-flex align-items-center justify-content-center">
                        <img src={bannerone} alt="home" className='banner-image' style={{maxHeight: '80vh'}}></img>
                    </div>
                </div>
            </div>      
        </div>
    );

}

const HubspotContactForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: '22797701',
                    formId: 'c1a96c7f-8a80-4462-b102-92ac7e90df38',
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-12 col-lg-6 overflow-hidden" style={{height: '80vh'}}>
                    <div className="w-100 h-100 d-flex justify-content-center flex-column align-items-center mt-lg-0">
                        <h1 className="larger-text fw-bold">Contact</h1>
                        <div id="hubspotForm" style={{maxWidth: '500px', width: '100%'}}></div>
                    </div>            
                </div>
            </div>      
        </div>
    )
}