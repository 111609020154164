import {useState, useEffect, useRef} from "react"

export function DynamicImage({ source }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isInView, setIsInView] = useState(false);
    const root = useRef(); // the container
  
    useEffect(() => {
      // sets `isInView` to true until root is visible on users browser
      console.log(isLoading)
      console.log(source)
      
      handleImageLoaded()
    }, []);
  
    function handleImageLoaded() {
      //setTimeout(() => setIsLoading(false), 2000)
      setIsLoading(false)
    }
  
    return (
        
        <div
            ref={root}
            className=""
            style={{width: '100vw', height: '100%'}}
        >
        { isLoading && 
            <div className="loading">
                <i class="ri-loader-2-line"></i>
            </div> 
        }
        
        { !isLoading && <img className="img" src={isLoading ? null : source} alt="#" onLoad={handleImageLoaded} /> }
        </div>
    );
  }

  export function LazyImage({ source }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isInView, setIsInView] = useState(false);
    const root = useRef(); // the container
  
    useEffect(() => {
      // sets `isInView` to true until root is visible on users browser
      console.log(isLoading)
      console.log(source)
      const observer = new IntersectionObserver(onIntersection, { threshold: 0 });
      observer.observe(root.current);
  
      function onIntersection(entries) {
        const { isIntersecting } = entries[0];
  
        if (isIntersecting) { // is in view
          observer.disconnect();
        }
  
        setIsInView(isIntersecting);
      }
    }, []);
  
    function onLoad() {
      setIsLoading((prev) => !prev);
    }
  
    return (
      <div
        ref={root}
        className={`imgWrapper` + (isLoading ? " imgWrapper--isLoading" : "")}
      >
        <div className="imgLoader" />
        <img className="img" src={isInView ? source : null} alt="" onLoad={onLoad} />
      </div>
    );
  }