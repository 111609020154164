import bannerone from './assets/block-home-9/Renders_1 - Photo.jpg';
import bannertwo from './assets/block-home-9/Renders_3 - Photo.jpg';
import bannerthree from './assets/block-home-3/b.jpg'
import ProductPage from './ProductPage';
import featureone from './assets/1.jpg';
import featuretwo from './assets/block-home-9/Renders_1 - Photo.jpg';
import featurethree from './assets/block-home-10/3renders_2 - Foto.jpg';
import featurefour from './assets/block-home-13/5.jpg';
import React, {Component, useEffect, useState} from "react";
import { db } from './firebaseConfig';
import { collection, doc, getDocs, runTransaction } from 'firebase/firestore'
import Slider from "react-slick";
import Tide from './assets/1.jpg';
import Tide2 from './assets/block-home-2/7.jpg';
import Tide3 from './assets/2.jpg';
import Agora from './assets/block-home-9/Renders_1 - Photo.jpg';
import Agora2 from './assets/block-home-9/Renders_5 - Photo.jpg';
import Agora3 from './assets/block-home-9/Renders_6 - Photo.jpg';
import Twoscompany from './assets/block-home-10/3renders_2 - Foto.jpg';
import Twoscompany2 from './assets/block-home-10/4renders_12 - Foto.jpg';
import Twoscompany3 from './assets/block-home-10/Renders_9 - Foto.jpg';
import Loggia from './assets/block-home-13/5.jpg';
import Loggia2 from './assets/block-home-13/15.jpg';
import Loggia3 from './assets/block-home-13/6.jpg';
import { Link } from 'react-router-dom';
import logo from './assets/block-logo.png';


export default function WhyBlock(){
    return (
        <div>
          <Banner />
          <Summary />
          <Why />
        </div>
    )
}

function Banner(){
  return (
      <div className="container-fluid border-dark">
       
              <div className="row position-relative overflow-hidden align-items-stretch" style={{height: '86vh', width: '100vw'}}>
                  <div className="line w-100 border-top lg-only" style={{position:'absolute',top:'10%',left:0, zIndex: 200}}></div>
                  <div className="line w-100 border-top lg-only" style={{position:'absolute',bottom:'0%',left:0, zIndex: 200}}></div>
                  <div className="col-md-3 d-flex justify-content-center flex-column align-items-center overflow-hidden p-1 px-5 px-md-2 px-sm-5 mh-100 md-no-border" style={{border: '6px solid white', background: '#000e00'}}>
                      <div className="border-left align-items-end d-flex h-100 w-100" style={{ zIndex: 30,bottom: 0, left:0}}>
                          <div className="text-light d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center p-1" style={{position:'absolute',bottom:'0%',left:0, zIndex: 200}}>
                              <h1 className="mb-0 fw-bolder big-text">Why Block?</h1>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-8 gx-0 overflow-hidden md-no-border height-fix" style={{border: "6px solid white", borderLeft: 'none'}}>
                      <div className="d-flex align-items-stretch justify-content-center adaptive-banner-container overflow-hidden">
                          <img src={bannertwo} alt="home" className='banner-image'></img>
                      </div>
                  </div>
                  
                  <div className="col-md-1 overflow-hidden md-no-border" style={{background: '#000e00', borderTop: '6px solid white', borderBottom: '6px solid white'}}>
                  </div>
              </div>
        
      </div>
  )
}

function Summary(){
    return (
        <div className="container-fluid border-dark">
         
                <div className="row position-relative overflow-hidden align-items-stretch" style={{height: '60vh', width: '100vw'}}>
                    <div className="line w-100 border-top lg-only" style={{position:'absolute',top:'0%',left:0, zIndex: 200}}></div>
                    <div className="line w-100 border-top lg-only" style={{position:'absolute',bottom:'15%',left:0, zIndex: 200}}></div>
                    <div className="col-md-3 d-flex justify-content-center flex-column align-items-center overflow-hidden p-1 px-5 px-md-2 px-sm-5 mh-100 md-no-border" style={{border: '6px solid white', background: '#000e00'}}>
                        <div className="border-left align-items-end d-flex h-100 w-100" style={{ zIndex: 30,bottom: 0, left:0}}>
                            <div className="text-light d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center p-5">
                            <a href='#' className="btn background-primary text-light rounded-0 small-text fw-bold my-4 w-75">Our Process</a>
                            <a href='#' className="btn background-primary text-light rounded-0 small-text fw-bold my-4 w-75">Residential</a>
                            <a href='#' className="btn background-primary text-light rounded-0 small-text fw-bold my-4 w-75">Commercial</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 gx-0 overflow-hidden md-no-border height-fix" style={{border: "6px solid white", borderLeft: 'none', background: '#000e00'}}>
                        <div className="d-flex align-items-stretch justify-content-center adaptive-banner-container overflow-hidden">
                            <h2 className='text-light big-text mt-5' style={{width: '75%'}}>Why Container Homes?</h2>
                        </div>
                        <div className="d-flex align-items-stretch justify-content-center adaptive-banner-container overflow-hidden">
                            <p className='text-light' style={{width: '50%'}}>Container homes are designed to be high quality, durable and affordable. If you want to save money and space, container homes can be a great alternative to traditional housing. Here are four reasons why:</p>
                        </div>
                    </div>
                    
                    <div className="col-md-1 overflow-hidden md-no-border" style={{background: '#000e00', borderTop: '6px solid white', borderBottom: '6px solid white'}}>
                    </div>
                </div>
          
        </div>
    )
  }

  function Why(){
    return (
        <div className="container-fluid border-dark">
         
                <div className="row position-relative overflow-hidden align-items-stretch justify-content-around pt-5" style={{height: '30vh', width: '100vw'}}>
                    
                    <div className='col-4'>
                        <h1>Less Money</h1>
                        <p>No traditional construction methods can match the costs for container home construction. They can be more cost-effective than traditional housing. Because there are fewer costs associated with building material and labor costs, you'll likely spend less on construction with even high-end container homes.</p>
                    </div>

                    <div className='col-4'>
                        <h1>More Flexible</h1>
                        <p>Container homes are durable, easy to modify and connect together. We provide a wide range of floor plans and layouts available from our standard designs to a custom build. This makes it easy for you to choose the design that best suits your needs, whether it’s for a second dwelling on your property or an investment property for Airbnb. Or if you want more space, we can use a mix of 20 and 40-foot containers to create a larger home with a living room, dining room, extra bedrooms, or even pull down into another floor for storage space.</p>
                    </div>

                </div>
                <div className="row position-relative overflow-hidden align-items-stretch justify-content-around pt-5" style={{height: '30vh', width: '100vw'}}>
                    
                    <div className='col-4'>
                        <h1>Less Time</h1>
                        <p>Building a container home is much faster than a traditional one. Traditional homes could take eight months to build, while we can build and ship a container home in under twelve weeks.</p>
                    </div>

                    <div className='col-4'>
                        <h1>More Sustainable</h1>
                        <p>Building a shipping container home is a great way to save time, money, and the environment. By using recycled materials in their construction, these homes offer an efficient way to build a beautiful home that you can feel good about. Container homes also provide durability with self-healing steel that is better to withstand inclement weather conditions than traditional housing. With Block, you get beauty and protection!</p>
                    </div>
                    
                </div>
                <div className="row position-relative overflow-hidden align-items-stretch justify-content-around pt-5" style={{height: '30vh', width: '100vw'}}>
                    
                    <div className='col-4'>
                        <h1>More Mobile</h1>
                        <p>Shipping containers make moving and transporting houses easier than ever before. Take your home anywhere you'd like! You can use a dedicated shipping transportation service to pick up and relocate an off-the-grid single-container house anywhere in the world.</p>
                    </div>
                    <div className='col-4'>
                    </div>
                    
                </div>
          
        </div>
    )
  }