import bannerone from './assets/block-home-9/Renders_1 - Photo.jpg';
import bannertwo from './assets/block-home-9/Renders_3 - Photo.jpg';
import bannerthree from './assets/block-home-3/b.jpg'
import ProductPage from './ProductPage';
import featureone from './assets/1.jpg';
import featuretwo from './assets/block-home-9/Renders_1 - Photo.jpg';
import featurethree from './assets/block-home-10/3renders_2 - Foto.jpg';
import featurefour from './assets/block-home-13/5.jpg';
import React, {Component, useEffect, useState} from "react";
import { db } from './firebaseConfig';
import { collection, doc, getDocs, runTransaction } from 'firebase/firestore'
import Slider from "react-slick";
import Tide from './assets/1.jpg';
import Tide2 from './assets/block-home-2/7.jpg';
import Tide3 from './assets/2.jpg';
import Agora from './assets/block-home-9/Renders_1 - Photo.jpg';
import Agora2 from './assets/block-home-9/Renders_5 - Photo.jpg';
import Agora3 from './assets/block-home-9/Renders_6 - Photo.jpg';
import Twoscompany from './assets/block-home-10/3renders_2 - Foto.jpg';
import Twoscompany2 from './assets/block-home-10/4renders_12 - Foto.jpg';
import Twoscompany3 from './assets/block-home-10/Renders_9 - Foto.jpg';
import Loggia from './assets/block-home-13/5.jpg';
import Loggia2 from './assets/block-home-13/15.jpg';
import Loggia3 from './assets/block-home-13/6.jpg';
import { Link } from 'react-router-dom';
import logo from './assets/block-logo.png';
import { DynamicImage } from './shared/DynamicImage';



export default function Home(){
    useEffect(() => {
        window._hsq.push(['setPath', '/'])
    }, [])

    return (
        <div>
            <Banner />
            <WeAre />
            <IndustriesNew/>
            {/*<Quote/>*/}
            <ModelsList />
            <HubspotContactForm />
            {/*<SimpleSlider/>*/}
            {/*<QuoteSlider/>*/}
           {/* <InspoSlider/> */}
        </div>
    )
}

function Banner(){
    return (
        <div className="container-fluid border-dark">
         
                <div className="row position-relative overflow-hidden align-items-stretch" style={{height: '86vh', width: '100vw'}}>
                    <div className="line w-100 border-top lg-only" style={{position:'absolute',top:'10%',left:0, zIndex: 200}}></div>
                    <div className="line w-100 border-top lg-only" style={{position:'absolute',bottom:'10%',left:0, zIndex: 200}}></div>
                    <div className="col-md-3 d-flex justify-content-center flex-column align-items-center overflow-hidden p-1 px-5 px-md-2 px-sm-5 mh-100 md-no-border" style={{border: '6px solid white', background: '#000e00'}}>
                        <div className="border-left align-items-end d-flex h-100 w-100" style={{ zIndex: 30,bottom: 0, left:0}}>
                            <div className="text-light d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center p-1">
                                <h1 className="mb-0 fw-bolder big-text">Block</h1>
                                <p className="mb-0">EXPERIENCE THE<br />FUTURE OF BUILDING.</p>
                                <a href='https://2cbrn7j00au.typeform.com/blockcompany?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx' className="btn background-primary text-light rounded-0 small-text fw-bold mt-4 w-100">START A PROJECT</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 gx-0 overflow-hidden md-no-border height-fix position-relative" style={{border: "6px solid white", borderLeft: 'none'}}>
                        <div className="d-flex align-items-end justify-content-center adaptive-banner-container overflow-hidden position-relative h-100">
                            {/*<img src={bannerone} alt="home" className='banner-image'></img>*/}
                            <DynamicImage source={bannerone}/>
                            {/*<div className="position-absolute p-5" style={{bottom: 0, left: 0, width: '100%', height: '100%', background: 'linear-gradient(0deg, rgba(254,254,254,0), rgba(0,0,0,.1))'}}>
                                <div className="text-light d-flex flex-column justify-content-center align-items-start w-100 h-100 text-start p-1">
                                    <h1 className="mb-0 fw-bolder big-text">Block</h1>
                                    <p className="mb-0">EXPERIENCE THE<br />FUTURE OF BUILDING.</p>
                                    <a href='https://2cbrn7j00au.typeform.com/blockcompany?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx' className="btn background-primary text-light rounded-0 small-text fw-bold mt-4 w-100" style={{maxWidth: '300px'}}>START A PROJECT</a>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                    
                    <div className="col-md-1 overflow-hidden md-no-border" style={{background: '#000e00', borderTop: '6px solid white', borderBottom: '6px solid white'}}>
                    </div>
                </div>
          
        </div>
    )
}

function WeAre(){
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <div className="d-flex flex-column justify-content-center align-items-center color-primary-dark" style={{height: '65vh'}}>
                        <h1 className="fw-bolder larger-text" style={{fontWeight: 800}}>We are a team devoted to innovative <br/>property development.</h1>
                        <p className="ms-auto me-auto" style={{maxWidth: '400px'}}>Collaborating with companies, communites and ambitious individuals to accelerate land usage.</p>
                        <a href='https://2cbrn7j00au.typeform.com/blockcompany?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx' className="btn background-primary text-light rounded-0 small-text fw-bold">START A PROJECT</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

function IndustriesNew(){
    return (
        <div className="container-fluid">
         
                <div className="row position-relative overflow-hidden lg-100-vh-then-grow">
                    <div className="col-lg-3 h-50 d-flex justify-content-center flex-column align-items-center overflow-hidden p-1 px-5 md-no-border" style={{border: '6px solid white', background: '#000e00'}}>
                        <div className="border-left align-items-end d-flex h-100 w-100" style={{ zIndex: 30,bottom: 0, left:0}}>
                            <div className="text-light d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center p-1">
                                <h1 className="mb-0 fw-bolder larger-text">RESIDENTIAL</h1>
                                <p className="mb-0">Homes</p>
                                <p className="mb-0">RV Certified</p>
                                <p className="mb-0">Mother-in-Law Suites</p>
                                <p className="mb-0">Outdoor Kitchen</p>
                                <p className="mb-0">Mobile</p>
                                <a href='https://2cbrn7j00au.typeform.com/blockcompany?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx' className="btn background-primary text-light rounded-0 small-text fw-bold mt-4 w-75">START A PROJECT</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 gx-0 h-50 overflow-hidden md-no-border" style={{border: "6px solid white", borderLeft: 'none'}}>
                        <div className="d-flex align-items-center justify-content-center position-relative h-100">
                            <img src={bannerone} alt="home" className='banner-image'></img>
                        </div>
                    </div>
                    <div className="col-lg-3 h-50 d-flex justify-content-center flex-column align-items-center overflow-hidden p-1 px-5 md-no-border" style={{border: '6px solid white', background: '#000e00'}}>
                        <div className="border-left align-items-end d-flex h-100 w-100" style={{ zIndex: 30,bottom: 0, left:0}}>
                            <div className="text-light d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center p-1">
                                <h1 className="mb-0 fw-bolder larger-text">COMMERCIAL</h1>
                                <p className="mb-0">Multi-family</p>
                                <p className="mb-0">Retail</p>
                                <p className="mb-0">Multi-Use Parks</p>
                                <p className="mb-0">Office</p>
                                <p className="mb-0">Storage</p>
                                <a href='https://2cbrn7j00au.typeform.com/blockcompany?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx' className="btn background-primary text-light rounded-0 small-text fw-bold mt-4 w-75">START A PROJECT</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 gx-0 h-50 overflow-hidden md-no-border" style={{border: "6px solid white", borderLeft: 'none'}}>
                        <div className="d-flex align-items-center justify-content-cente h-100">
                            <img src={bannerthree} alt="home" className='banner-image'></img>
                        </div>
                    </div>
                    <div className="col-lg-3 h-50 d-flex justify-content-center flex-column align-items-center overflow-hidden p-1 px-5 md-no-border" style={{border: '6px solid white', background: '#000e00'}}>
                        <div className="border-left align-items-end d-flex h-100 w-100" style={{ zIndex: 30,bottom: 0, left:0}}>
                            <div className="text-light d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center p-1">
                                <h1 className="mb-0 fw-bolder larger-text">HOSPITALITY</h1>
                                <p className="mb-0">Food & Beverage</p>
                                <p className="mb-0">Bars & Breweries</p>
                                <p className="mb-0">Recreation</p>
                                <p className="mb-0">Tourism</p>
                                <p className="mb-0">Restrooms</p>
                                <a href='https://2cbrn7j00au.typeform.com/blockcompany?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx' className="btn background-primary text-light rounded-0 small-text fw-bold mt-4 w-75">START A PROJECT</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 gx-0 h-50 overflow-hidden md-no-border" style={{border: "6px solid white", borderLeft: 'none'}}>
                        <div className="d-flex align-items-center justify-content-center h-100">
                            <img src={bannertwo} alt="home" className='banner-image'></img>
                        </div>
                    </div>
                    <div className="col-lg-3 h-50 d-flex justify-content-center flex-column align-items-center overflow-hidden p-1 px-5 md-no-border" style={{border: '6px solid white', background: '#000e00'}}>
                        <div className="border-left align-items-end d-flex h-100 w-100" style={{ zIndex: 30,bottom: 0, left:0}}>
                            <div className="text-light d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center p-1">
                                <h1 className="mb-0 fw-bolder larger-text">EVENTS & MORE</h1>
                                <p className="mb-0">Exhibits & Festivals</p>
                                <p className="mb-0">Tradeshows</p>
                                <p className="mb-0">Concession Stands</p>
                                <p className="mb-0">Fan Zone & Activation</p>
                                <a href='https://2cbrn7j00au.typeform.com/blockcompany?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx' className="btn background-primary text-light rounded-0 small-text fw-bold mt-4 w-75">START A PROJECT</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 gx-0 h-50 overflow-hidden md-no-border" style={{border: "6px solid white", borderLeft: 'none'}}>
                        <div className="d-flex align-items-center justify-content-center h-100">
                            <img src={featurethree} alt="home" className='banner-image'></img>
                        </div>
                    </div>
                </div>
          
        </div>
    )
}


function ModelsList(){
    const [models, setModels] = useState([])
    const modelsCollectionRef = collection(db, "Models")

    useEffect(() => {
        if( !models.length ){ getModels() }  
        console.log(models) 
    }, [])

    const getModels = async () => {
        const data = await getDocs(modelsCollectionRef)
        console.log(data.docs)
        setModels(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    return (
        <div className="container-fluid border-dark">  
                <div className="col-12">
                    <div>
                        <h1 className="mb-0 fw-bolder larger-text color-primary-dark py-3 pt-5 mt-5">Residential Models</h1>
                    </div>
                </div>       
                <div className="row position-relative overflow-hidden">
                    {
                        models.map((model) => {
                            return (
                                <Link to={`/product/${model.id}`} className="col-lg-4 col-12 gx-0 position-relative overflow-hidden" style={{border: '6px solid white', height: '600px'}}>
                                    <div className="w-100 h-100 d-flex justify-content-center align-items-center text-start">
                                        <img src={model.images[1]} className="h-100" alt="#"></img>
                                        <div className="w-100 position-absolute text-light p-2 px-2 d-flex" style={{background: 'linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,1))', bottom: 0, left: 0, borderTop: '2px solid white'}}>
                                            <div className="w-50">
                                                <h1 className="mb-0 fw-bolder">{model.name}</h1>
                                                <a href='/' className="btn background-primary text-light rounded-0 small-text fw-bold mt-4 w-100">VIEW</a>                            
                                            </div>
                                            <div className="text-light p-2 pt-0 pb-0 ps-4 m-3 mt-0 mb-0">
                                                <p className="mb-1" style={{height: '50px', overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: 'Blinker, sans-serif'}}>{model.description}</p>
                                                <div>
                                                    <p className="mb-0"><span className="fw-bold">{model.bedrooms}</span> Bed | <span className="fw-bold">{model.bathrooms}</span> Bath</p>
                                                    <p className="mb-0"><span className="fw-bold">{model.squarefootage}</span> Square Feet</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                    {/*<div className="col-lg-4 col-12 gx-0 position-relative overflow-hidden" style={{border: '6px solid white'}}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center text-start">
                            <img src={bannertwo} className="h-100" alt="#"></img>
                            <div className="w-100 position-absolute text-light p-2 px-4 d-flex" style={{background: 'linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,1))', bottom: 0, left: 0, borderTop: '6px solid white'}}>
                                <div className="w-25">
                                    <h1 className="mb-0 fw-bolder">Loggia</h1>
                                    <a href='/' className="btn background-primary text-light rounded-0 small-text fw-bold mt-4 w-100">VIEW</a>                            
                                </div>
                                <div className="w-75 text-light p-2 pt-0 pb-0 px-4 m-3 mt-0 mb-0">
                                    <p className="mb-1">As the Greek translation means open meeting area this layout is perfect for bringing a family together.</p>
                                    <div>
                                        <p className="mb-0"><span className="fw-bold">1</span> Bed | <span className="fw-bold">1</span> Bath</p>
                                        <p className="mb-0"><span className="fw-bold">320</span> Square Feet</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 gx-0 position-relative overflow-hidden h-100" style={{border: '6px solid white'}}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center text-start">
                            <img src={bannerthree} className="h-100" alt="#"></img>
                            <div className="w-100 position-absolute text-light p-2 px-4 d-flex" style={{background: 'linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,1))', bottom: 0, left: 0, borderTop: '6px solid white'}}>
                                <div className="w-25">
                                    <h1 className="mb-0 fw-bolder">Loggia</h1>
                                    <a href='/' className="btn background-primary text-light rounded-0 small-text fw-bold mt-4 w-100">VIEW</a>                            
                                </div>
                                <div className="w-75 text-light p-2 pt-0 pb-0 px-4 m-3 mt-0 mb-0">
                                    <p className="mb-1">As the Greek translation means open meeting area this layout is perfect for bringing a family together.</p>
                                    <div>
                                        <p className="mb-0"><span className="fw-bold">1</span> Bed | <span className="fw-bold">1</span> Bath</p>
                                        <p className="mb-0"><span className="fw-bold">320</span> Square Feet</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}                    
                </div>
          
        </div>
    )
}

const HubspotContactForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: '22797701',
                    formId: 'c1a96c7f-8a80-4462-b102-92ac7e90df38',
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-lg-6 overflow-hidden" style={{height: '80vh'}}>
                    <div className="w-100 h-100 d-flex justify-content-center flex-column align-items-center mt-lg-0 mt-5">
                        <h1 className="larger-text fw-bold">Contact</h1>
                        <div id="hubspotForm" style={{maxWidth: '500px', width: '100%'}}></div>
                    </div>            
                </div>
                <div className="col-lg-6 gx-0 h-50 overflow-hidden desktop-only" style={{border: "6px solid white", borderLeft: 'none'}}>
                    <div className="d-flex align-items-center justify-content-center">
                        <img src={bannerone} alt="home" className='banner-image' style={{maxHeight: '80vh'}}></img>
                    </div>
                </div>
            </div>      
        </div>
    );

} 