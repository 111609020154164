import { Link } from 'react-router-dom';
import logo from './assets/block-logo-black.png';
import favi from './assets/block-favicon.jpeg';
import featureone from './assets/1.jpg';
import featuretwo from './assets/block-home-9/Renders_1 - Photo.jpg';
import featurethree from './assets/block-home-10/3renders_2 - Foto.jpg';
import featurefour from './assets/block-home-13/5.jpg';
import { Component } from 'react';
import Slider from "react-slick";

export default function Navigation(){
    return (
        <div>
            {/*<div className="container-fluid g-0">
                <div className="row g-0">
                    <div className="col-12 bg-dark py-2 px-3">
                        <div className="d-flex space-between ms-auto me-auto align-items-center" style={{maxWidth: '1400px'}}>               
                            <Link to="/" class="navbar-brand" href="#"><img src={logo} alt="logo" style={{'width': '70px'}}></img></Link>
                            <a href='https://2cbrn7j00au.typeform.com/blockcompany?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx' class="btn btn-sm btn-primary ms-auto">Start a Project</a>
                        </div>
                    </div>
                </div>
            </div>
           
            <nav class="navbar navbar-expand navbar-dark bg-light border-bottom pt-0" >
                <div class="flex-column align-items-start">   
                    <div className="bg-light py-2 d-flex" style={{width: '100vw'}}>
                            <ul className="navbar-nav me-auto ms-auto" style={{width: '1400px', maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto'}}>
                                <li className="nav-item ms-3 me-3 clickable fw-bold" data-bs-toggle="modal" data-bs-target="#exampleModal">Models</li>
                                <li className="nav-item me-3 fw-bold"><Link to="/about" className="text-dark">About</Link></li>
                                <li className="nav-item me-3 fw-bold"><Link to="/contact" className="text-dark">Contact</Link></li>
                            </ul>
                    </div>
                </div>
            </nav>*/}

            <nav class="navbar navbar-expand-sm" style={{background: 'transparent'}}>
                <div class="container-fluid" id="block-nav-inner">
                    <Link to="/" class="navbar-brand" id="block-nav-brand" href="#">
                        <div className="d-flex align-items-center">
                            <img src={favi} alt="favi" style={{width: '34px'}}></img>
                            <img src={logo} alt="logo" className="ms-2" style={{'height': '12px'}}></img>
                        </div>
                    </Link>
                    <ul class="navbar-nav" id="block-nav-ul">
                        <li className="nav-item me-3 clickable fw-bold text-dark" data-bs-toggle="modal" data-bs-target="#exampleModal">Models</li>
                        <li className="nav-item me-3 fw-bold"><Link to="/about" className="text-dark">About</Link></li>
                        <li className="nav-item me-3 fw-bold"><Link to="/whyblock" className="text-dark">Why Block</Link></li>
                        <li className="nav-item me-3 fw-bold"><Link to="/contact" className="text-dark">Contact</Link></li>
                        <li className="nav-item me-3 fw-bold"><a href='https://2cbrn7j00au.typeform.com/blockcompany?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx' class="text-dark">Start a Project</a></li>
                    </ul>
                </div>
            </nav>
            <ProductsModal />
        </div>
    )
}

function ProductsModal(){

    return (
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Models</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body p-0">
            <ul class="list-group list-group-flush text-start">
                <Link to="/products" data-bs-dismiss="modal" aria-label="Close">
                    <li class="list-group-item text-start">
                        <div className="d-flex align-items-center">
                            <div className="overflow-hidden rounded position-relative me-3" style={{'height': '60px', 'width': '60px'}}>
                                <img src={featureone} alt="tide" className="h-100" />
                            </div>  
                            <div className="text-start">
                                <p className="mb-0 fw-bold">All Models</p>
                                <p className="mb-0"><small className="text-secondary">Browse Standard Models</small></p>
                            </div>                      
                            
                        </div>
                    </li>
                </Link>
                <li className="list-group-item fw-bold py-3 text-secondary">1 Bedroom</li>
                <Link to="/product/tide" data-bs-dismiss="modal" aria-label="Close">
                    <li class="list-group-item text-start">
                        <div className="d-flex align-items-center">
                            <div className="overflow-hidden rounded position-relative me-3" style={{'height': '60px', 'width': '60px'}}>
                                <img src={featureone} alt="tide" className="h-100" />
                            </div>  
                            <div className="text-start">
                                <p className="mb-0 fw-bold">Tide</p>
                                <p className="mb-0"><small className="text-secondary">1 Bed | 1 Bath | 320 sqft.</small></p>
                            </div>                      
                            
                        </div>
                    </li>
                </Link>
                <Link to="/product/high-tide" data-bs-dismiss="modal">
                <li class="list-group-item text-start">
                    <div className="d-flex align-items-center">
                        <div className="overflow-hidden rounded position-relative me-3" style={{'height': '60px', 'width': '60px'}}>
                            <img src={featurefour} alt="tide" className="h-100" />
                        </div>  
                        <div className="text-start">
                            <p className="mb-0 fw-bold">High Tide</p>
                            <p className="mb-0"><small className="text-secondary">1 Bed | 1 Bath | 320 sqft.</small></p>
                        </div>                      
                        
                    </div>
                </li>
                </Link>
                <Link to="/product/agora" data-bs-dismiss="modal">
                    <li class="list-group-item text-start">
                        <div className="d-flex align-items-center">
                            <div className="overflow-hidden rounded position-relative me-3" style={{'height': '60px', 'width': '60px'}}>
                                <img src={featuretwo} alt="tide" className="h-100" />
                            </div>  
                            <div className="text-start">
                                <p className="mb-0 fw-bold">Agora</p>
                                <p className="mb-0"><small className="text-secondary">1 Bed | 1 Bath | 320 sqft.</small></p>
                            </div>                      
                        </div>
                    </li>
                </Link>

                <li className="list-group-item fw-bold py-3 text-secondary">2 Bedroom</li>
                <Link to="/product/twos-company" data-bs-dismiss="modal">
                    <li class="list-group-item text-start">
                        <div className="d-flex align-items-center">
                            <div className="overflow-hidden rounded position-relative me-3" style={{'height': '60px', 'width': '60px'}}>
                                <img src={featurefour} alt="tide" className="h-100" />
                            </div>  
                            <div className="text-start">
                                <p className="mb-0 fw-bold">Two's Company</p>
                                <p className="mb-0"><small className="text-secondary">2 Bed | 1 Bath | 480 sqft.</small></p>
                            </div>                      
                            
                        </div>
                    </li>
                </Link>
                <Link to="/product/loggia" data-bs-dismiss="modal">
                    <li class="list-group-item text-start">
                        <div className="d-flex align-items-center">
                            <div className="overflow-hidden rounded position-relative me-3" style={{'height': '60px', 'width': '60px'}}>
                                <img src={featurethree} alt="tide" className="h-100" />
                            </div>  
                            <div className="text-start">
                                <p className="mb-0 fw-bold">Loggia</p>
                                <p className="mb-0"><small className="text-secondary">2 Bed | 1 Bath | 640 sqft.</small></p>
                            </div>                      
                            
                        </div>
                    </li>
                </Link>
            </ul>
            </div>
        
            </div>
        </div>
        </div>
    )
}

class NavigationSlider extends Component {
    render() {
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        centerMode: true,
        slidesToScroll: 1
      };
      return (
        <div className='pb-5'>
          <Slider {...settings}>
            <ul className="navbar-nav">
                <li className="nav-item me-3 fw-bold"><Link to="/" className="text-dark">Company</Link></li>
                <li className="nav-item me-3 fw-bold" data-bs-toggle="modal" data-bs-target="#exampleModal">Model</li>
                <li className="nav-item me-3 fw-bold"><Link to="/products" className="text-dark">Models</Link></li>
                <li className="nav-item me-3 fw-bold"><Link to="/process" className="text-dark">Process</Link></li>
            </ul>
          </Slider>
        </div>
        
      );
    }
  }