import React, { Component } from "react";
import Slider from "react-slick";

export function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
        className={className}
        style={{ ...style, display: "block", background: "none", right: "20px", "zIndex": 300 }}
        onClick={onClick}
        >
          <i class="bi bi-arrow-right"></i>
        </div>
    );
  }
  
export function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "none", left: "10px", "zIndex": 300 }}
        onClick={onClick}
      >
          <i class="bi bi-arrow-left" style={{fontSize: '200%'}}></i>
      </div>
    );
  }
  