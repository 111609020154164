import logo from './logo.svg';
import './App.css';
import Home from './Home';
import Navigation from './Navigation'
import ProductPage from './ProductPage';
import OurProcess from './OurProcess';
import ProductListPage from './ProductListPage';
import AboutPage from './AboutPage';
import Footer from './Footer';
import ReactGA from "react-ga4";
import { useEffect } from 'react';
import {
  Routes,
  Route,
} from "react-router-dom"
import { ContactForm } from './ContactForm';
import { GeneralContact } from './GeneralContact';
import WhyBlock from './WhyBlock';

function App() {
  const TRACKING_ID = "G-6KQWP2PW8T";
  ReactGA.initialize(TRACKING_ID)
  
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  return (
    <div className="App" style={{maxWidth: '100vw', overflowX: 'hidden'}}>
      <Navigation />
      <div className="ms-auto me-auto" style={{'max-width': '100%', 'width': '100vw'}}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="products" element={<ProductListPage />} />
          <Route path="product/:id" element={<ProductPage />} />
          <Route path="process" element={<OurProcess />} />
          {/*<Route path="contact" element={<GeneralContact />} />*/}
          <Route path="contact" element={<ContactForm />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="start-a-project" element={<ContactForm />} />
          <Route path="whyblock" element={<WhyBlock />} />
        </Routes>
      </div>
      {/* <Footer/> */}
    </div>
  );
}

export default App;
