import { useEffect, useState } from 'react';
import React, {Component} from "react";
import Slider from "react-slick";

import { CustomPrevArrow, CustomNextArrow } from './SliderComponets';
import { useParams } from 'react-router';
import { doc, getDoc } from '@firebase/firestore';

import { db } from './firebaseConfig'


export default function ProductPage(){
    const [product, setProduct] = useState({})
    let { id } = useParams() 
    const productRef = doc(db, "Models", id)

    useEffect(() => {
        window._hsq.push(['setPath', `product/${id}`])
        const getModel = async () => {
            console.log(id)
            const data = await getDoc(productRef)
            
            console.log(data)

            if (data.exists()) {
                setProduct(data.data())
                console.log("Document data:", product);
              } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
              }
        }

        getModel()

    }, [id])

    return (
        <div className="container-fluid pt-4">
            <div className="row">
                <div className="col-12 col-md-8">
                    <div className="row p-md-4">
                        <div className="col-12">
                            <div class="row">
                                <div className="col-6 text-start">
                                    <h3 className="fw-bold">{product.name}</h3>
                                </div>
                                <div className="col-6 text-end">
                                    <p>{product.bedrooms} Bed | {product.bathrooms} Bath</p>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-12 overflow-hidden justify-content-center align-items-center">
                            { product.images && <ProductImageSlider product={product}/> }
                        </div>

                        <div className="col-12 mb-5">
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="text-start fw-bold mt-3"><span className="text-muted text-small fw-light" style={{'fontSize': '70%'}}>Base Price</span><br/> <span className="text-dark">${product.pricestring}</span></h3>
                                <button className="btn btn-primary btn-sm" type="button" data-bs-toggle="modal" data-bs-target="#imagesModal">All Photos</button>
                            </div>
                            <p className="text-start mb-3 mt-3 text-secondary">{product.description}</p>

                            <div className="text-start">
                                <p className="fw-bold mb-0 border-top pt-2">Bedrooms</p>
                                <p className="text-secondary"><span className="text-dark">{product.bedrooms}</span> Studio Bedroom</p>
                                <p className="fw-bold mb-0 border-top pt-2">Bathrooms</p>
                                <p className="text-secondary"><span className="text-dark">{product.bathrooms}</span> Bathroom</p>
                                <p className="fw-bold mb-0 border-top pt-2">Dimensions</p>
                                <p className="text-secondary"><span className="text-dark">{product.length}</span> ft. Length <br/><span className="text-dark">{product.width}</span> ft. Width <br/><span className="text-dark">{product.height}</span> ft. Height</p>
                                <p className="fw-bold mb-0 border-top pt-2">Square Footage</p>
                                <p className="text-secondary"><span className="text-dark">{product.squarefootage}</span> Square Feet</p>
                            </div>                    
                        </div>
                    </div>

                    { product && product.floorplan &&<FloorPlan product={product} /> }
                    <StandardOptions />
                </div>
                <div className="col-12 col-md-4">
                    { product && product.price && <Customizer product={product}/> }
                </div>
            </div>

            {product && product.images && <ProductImagesModal product={product}/>}
        </div>
    )
}

function StandardOptions(){
    return (
        <div className="col-12">
            <div className="row">
                <div className="col-12 pb-5">
                    <p className="fw-bold mb-0 pt-2 text-uppercase fs-5 text-center">Standard Build Options</p>
                    <p className="mb-1 text-center">Your Home will come loaded with features</p>
                </div>
                <div className="col-6 mb-4">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="fw-bold mb-0">Exterior Paint</p>
                        <div className="d-flex mt-1">
                            <div className="text-center d-flex justify-content-center align-items-center flex-column me-2">
                                <div className="rounded border" style={{'height': '40px', 'width': '40px', 'background': 'blue'}}></div>
                                <p><small>Blue</small></p>
                            </div>
                            <div className="text-center d-flex justify-content-center align-items-center flex-column me-2">
                                <div className="rounded border" style={{'height': '40px', 'width': '40px', 'background': '#888'}}></div>
                                <p><small>Grey</small></p>
                            </div>
                            <div className="text-center d-flex justify-content-center align-items-center flex-column me-2">
                                <div className="rounded border" style={{'height': '40px', 'width': '40px', 'background': 'black'}}></div>
                                <p><small>Black</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 mb-4">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="fw-bold mb-0">Windows</p>
                        <div className="d-flex mt-1">
                            <div className="text-center d-flex justify-content-center align-items-center flex-column me-2">
                                <div className="rounded border" style={{'height': '40px', 'width': '40px', 'background': 'white'}}></div>
                                <p><small>White Vinyl</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 mb-4">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="fw-bold mb-0">Interior Walls & Ceiling</p>
                        <div className="d-flex mt-1">
                            <div className="text-center d-flex justify-content-center align-items-center flex-column me-2">
                                <div className="rounded border" style={{'height': '40px', 'width': '40px', 'background': 'white'}}></div>
                                <p><small>Drywall</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 mb-4">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="fw-bold mb-0">Interior Paint</p>
                        <div className="d-flex mt-1">
                            <div className="text-center d-flex justify-content-center align-items-center flex-column me-2">
                                <div className="rounded border" style={{'height': '40px', 'width': '40px', 'background': '#999'}}></div>
                                <p><small>Agreeable Grey</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 mb-4">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="fw-bold mb-0">Baseboard Trim</p>
                        <div className="d-flex mt-1">
                            <div className="text-center d-flex justify-content-center align-items-center flex-column me-2">
                                <div className="rounded border" style={{'height': '40px', 'width': '40px', 'background': '#999'}}></div>
                                <p><small>White</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 mb-4">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="fw-bold mb-0">Fixtures & Hardware</p>
                        <div className="d-flex mt-1">
                            <div className="text-center d-flex justify-content-center align-items-center flex-column me-2">
                                <div className="rounded border" style={{'height': '40px', 'width': '40px', 'background': 'black'}}></div>
                                <p><small>Black</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 mb-4">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="fw-bold mb-0">Countertops</p>
                        <div className="d-flex mt-1">
                            <div className="text-center d-flex justify-content-center align-items-center flex-column me-2">
                                <div className="rounded border" style={{'height': '40px', 'width': '40px', 'background': 'wheat'}}></div>
                                <p><small>Butcher Block</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 mb-4">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="fw-bold mb-0">Interior Door</p>
                        <div className="d-flex mt-1">
                            <div className="text-center d-flex justify-content-center align-items-center flex-column me-2">
                                <div className="rounded border" style={{'height': '40px', 'width': '40px', 'background': 'brown'}}></div>
                                <p><small>Custom built stained pine barn door</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function FloorPlan({product}){
    return(
        <div className="row">
            <div className="col-12 position-relative mb-4">
                <img src={product.floorplan} alt="#" className="w-100"></img>
            </div>
        </div>
    )
}

function Customizer({product}){
    console.log(product)
    const [total, setTotal] = useState(product.price);
    const [customItems, setCustomItems] = useState([]);

    let toggleItem = (itemId, itemPrice) =>{
        console.log(customItems);
        console.log(itemPrice)
        if(customItems.includes(itemId)){
            setCustomItems(customItems.filter(item => item != itemId));
            setTotal(parseInt(total) - parseInt(itemPrice));
        }else{
            console.log(itemId)
            setCustomItems(arr => [...arr, itemId]);
            setTotal(parseInt(total) + parseInt(itemPrice));
            console.log(total)
            console.log(itemPrice)
        }        
    }

    return(
        <div className="row text-start pt-5 mt-md-4 border rounded position-relative" style={{paddingBottom: '100px'}}>
            <div className="col-12 px-md-5">
                <div className="text-start">
                    <p className="fw-bold mb-0 pt-2 text-uppercase fs-5 text-center">Customize your Home</p>
                    <p className="mb-1 text-center">Select upgrade options below to price your Home.</p>
                    <p className="text-center text-muted"><small>All Prices are USD</small></p>
                    <div className="row mt-5">
                        <div className="col-8 offset-2 col-md-12 offset-md-0 text-center mb-4 rounded-3 border border-secondary py-2">
                            <p className="mb-1 text-muted fw-light">Agora + Upgrades Total</p>
                            <h5 className="">$<span className="fw-bold">{total}</span></h5>
                            <p className="mb-1 text-muted fw-light">{customItems.length} Upgrade(s)</p>
                        </div>                        
                    </div>
                    
                    <div className={`row mt-4 mt-md-0 py-3 hover-color cursor-pointer border-bottom ${customItems.includes('Technology Package') ? 'border-info border border-2': ''}`} onClick={() => {toggleItem('Technology Package', 3000);}}>
                        <div className={`col-11 col-md-12`}>
                            <p className="fw-bold mb-0">Technology Package</p>
                            <p className="mb-1 text-secondary">Smart Lighting, Nex X Yale Smart door lock, Alexa Echo Spot, Nest Smart Smoke and Carbon Dioxide Alarm, Nest Doorbell</p>
                            <p className="fw-bold mb-0 text-uppercase">$3,000</p>
                        </div>
                    </div>

                    <div className={`row mt-4 mt-md-0 py-3 hover-color cursor-pointer border-bottom ${customItems.includes('Cabinet Additions') ? 'border-info border border-2': ''}`} onClick={() => {toggleItem('Cabinet Additions', 250);}}>
                        <div className={`col-11 col-md-12 `}>
                            <p className="fw-bold mb-0">Cabinet Additions</p>
                            <p className="mb-1 text-secondary">Additional Over the Kitchen window cabinet</p>
                            <p className="fw-bold mb-0 text-uppercase">$250</p>
                        </div>
                    </div>
                    <div className={`row mt-4 mt-md-0 py-3 hover-color cursor-pointer border-bottom ${customItems.includes('Washer/Dryer') ? 'border-info border border-2': ''}`} onClick={() => {toggleItem('Washer/Dryer', 2000);}}>
                        <div className={`col-11 col-md-12`}>
                            <p className="fw-bold mb-0">Washer & Dryer</p>
                            <p className="mb-1 text-secondary">Additional Over the Kitchen window cabinet</p>
                            <p className="fw-bold mb-0 text-uppercase">$2,000</p>
                        </div>
                    </div>
                    <div className={`row mt-4 mt-md-0 py-3 hover-color cursor-pointer border-bottom ${customItems.includes('Pocket Door') ? 'border-info border border-2': ''}`} onClick={() => {toggleItem('Pocket Door', 400);}}>
                        <div className={`col-11 col-md-12`}>
                            <p className="fw-bold mb-0">Pocket Door</p>
                            <p className="mb-1 text-secondary">Pocket door in place of barn door</p>
                            <p className="fw-bold mb-0 text-uppercase">$400</p>
                        </div>
                    </div>
                    <div className={`row mt-4 mt-md-0 py-3 hover-color cursor-pointer border-bottom ${customItems.includes('Bedroom Storage Pack 1') ? 'border-info border border-2': ''}`} onClick={() => {toggleItem('Bedroom Storage Pack 1', 2000);}}>
                        <div className={`col-11 col-md-12`}>
                            <p className="fw-bold mb-0">Bedroom Storage Pack 1</p>
                            <p className="mb-1 text-secondary">Custom side-tilting steel strorage bed frame</p>
                            <p className="fw-bold mb-0 text-uppercase">$2000</p>
                        </div>
                    </div>
                    <div className={`row mt-4 mt-md-0 py-3 hover-color cursor-pointer border-bottom ${customItems.includes('Bedroom Storage Pack 2') ? 'border-info border border-2': ''}`} onClick={() => {toggleItem('Bedroom Storage Pack 2', 1200);}}>
                        <div className={`col-11 col-md-12`}>
                            <p className="fw-bold mb-0">Bedroom Storage Pack 2</p>
                            <p className="mb-1 text-secondary">Custom steel loft bed frame</p>
                            <p className="fw-bold mb-0 text-uppercase">$1200</p>
                        </div>
                    </div>
                    <div className={`row mt-4 mt-md-1 py-3 hover-color cursor-pointer border-bottom ${customItems.includes('Molding Package') ? 'border-info border border-2': ''}`} onClick={() => {toggleItem('Molding Package', 1300);}}>
                        <div className={`col-11 col-md-12`}>
                            <p className="fw-bold mb-0">Molding Package</p>
                            <p className="mb-1 text-secondary">Crown molding throughout home</p>
                            <p className="fw-bold mb-0 text-uppercase">$1300</p>
                        </div>
                    </div>
                    <div className={`row mt-4 mt-md-0 py-3 hover-color cursor-pointer border-bottom ${customItems.includes('Range Upgrade') ? 'border-info border border-2': ''}`} onClick={() => {toggleItem('Range Upgrade', 1000);}}>
                        <div className={`col-11 col-md-12`}>
                            <p className="fw-bold mb-0">Range Upgrade</p>
                            <p className="mb-1 text-secondary">Fridgidaire - 24 in. 1.9 cu. ft. freestanding electric range with manual clean in stainless steel</p>
                            <p className="fw-bold mb-0 text-uppercase">$1000</p>
                        </div>
                    </div>
                    <div className={`row mt-4 mt-md-0 py-3 hover-color cursor-pointer border-bottom ${customItems.includes('Microwave Add') ? 'border-info border border-2': ''}`} onClick={() => {toggleItem('Microwave Add', 1000);}}>
                        <div className={`col-11 col-md-12`}>
                            <p className="fw-bold mb-0">Microwave Add</p>
                            <p className="mb-1 text-secondary">Fridgidaire 1.4 cu. ft. over-the-range microwave in stainless steel with automatic sensor</p>
                            <p className="fw-bold mb-0 text-uppercase">$800</p>
                        </div>
                    </div>
                    <div className={`row mt-4 mt-md-0 py-3 hover-color cursor-pointer border-bottom ${customItems.includes('Board and Batten') ? 'border-info border border-2': ''}`} onClick={() => {toggleItem('Board and Batten', 3500);}}>
                        <div className={`col-11 col-md-12`}>
                            <p className="fw-bold mb-0">Board and Batten</p>
                            <p className="mb-1 text-secondary">Interior board and batten design</p>
                            <p className="fw-bold mb-0 text-uppercase">$3500</p>
                        </div>
                    </div>
                </div>
            </div>
            <ProductMainButton total={total}/>
        </div>
    )
}

class ProductImageSlider extends Component {  
    constructor(props){
        super(props)
    }

    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        centerMode: false,
        slidesToScroll: 1,
        mobileFirst: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 645,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    centerMode: false
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    centerMode: false
                }
            },
            {
                breakpoint: 3000,
                settings: {
                    slidesToShow: 3,
                    centerMode: false
                }
            },
        ]
      };
      return (
        <div className='pb-5'>
          <Slider {...settings}>
            {this.props.product.images.map((image) => {
                return(
                  <div>
                    <div className='rounded m-3 m-md-1 overflow-hidden d-flex justify-content-center' data-bs-toggle="modal" data-bs-target="#imagesModal">
                        <img style={{'height': '270px'}} src={image} alt="home"></img>
                    </div>
                  </div>
                )
            })}
            
          </Slider>
        </div>
        
      );
    }
  }

  const ProductMainButton = ({total}) =>{
        return (
            <div className="fixed-bottom left w-100 p-3 ms-auto me-auto" style={{'zIndex': 100, width: '100%', 'maxWidth': '400px'}}>
                <a href="https://2cbrn7j00au.typeform.com/blockcompany?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx">
                    <div className="btn btn-large btn-primary w-100 d-flex d-flex align-items-center justify-content-between">
                        <div className="text-start border-right border-light me-3">
                            <p className="fw-light m-0"><small>Price w/ Upgrades</small></p>
                            <p className="fw-light m-0 fs-5">$<span className="fw-bold">{total}</span></p>
                        </div>
                        <p className="m-1 fs-4 fw-bold">Get a Quote</p>
                    </div>
                </a>
            </div>
        )
  }

  const ProductImagesModal = ({product}) => {
      return (
        <div class="modal fade" id="imagesModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">{product.name} Images</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {product.images.map((image) => {
                  return (
                    <img className="mb-1 w-100" src={image} alt="img1"></img>
                  )
              })}
              
              
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      )
  }
